export default {
  name: "TaskAcceptSuccess",
  props: {
    id: String,
  },
  data() {
    return {};
  },
  methods: {
    gotoComplete() {
      // console.log("sdfdsf");
      this.$axios({
        method: "post",
        url: "/api/mobile/task/accomplishTask",
        data: {
          uuid: this.id,
        },
      }).then((res) => {
        console.log("res.data", res.data);
        if (res.data.code === 200) {
          const gotoUrl = res.data.data;
          window.location.href = gotoUrl;
        }
      });
    },
  },
};
